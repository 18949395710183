define("discourse/plugins/hosted-site/discourse/components/hosted-plugins-info", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/plugins/hosted-site/discourse/components/modal/show-business-plugins", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _object, _computed, _service, _showBusinessPlugins2, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if standardTier}}
    <br />
    <button
      class="btn btn-primary"
      type="button"
      {{action "showBusinessPlugins"}}
    >
      Need more plugins?
    </button>
  {{/if}}
  */
  {
    "id": "e1SK+l4c",
    "block": "[[[41,[30,0,[\"standardTier\"]],[[[1,\"  \"],[10,\"br\"],[12],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,1],[[30,0],\"showBusinessPlugins\"],null],[12],[1,\"\\n    Need more plugins?\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `standardTier` property path was used in the `discourse/plugins/hosted-site/discourse/components/hosted-plugins-info.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.standardTier}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"action\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/hosted-plugins-info.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  class HostedPluginInfo extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "standardTier", [(0, _computed.equal)("site.hosting_tier", "standard")]))();
    #standardTier = (() => (dt7948.i(this, "standardTier"), void 0))();
    showBusinessPlugins() {
      this.modal.show(_showBusinessPlugins2.default);
    }
    static #_3 = (() => dt7948.n(this.prototype, "showBusinessPlugins", [_object.action]))();
  }
  _exports.default = HostedPluginInfo;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HostedPluginInfo);
});