define("discourse/plugins/hosted-site/discourse/connectors/admin-customize-themes/admin-customize-themes-override", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("needToUpgrade", component.siteSettings.hide_admin_customize_themes);
    }
  };
});